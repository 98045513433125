exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-adatkezelesi-tajekoztato-tsx": () => import("./../../../src/pages/adatkezelesi-tajekoztato.tsx" /* webpackChunkName: "component---src-pages-adatkezelesi-tajekoztato-tsx" */),
  "component---src-pages-apple-vision-pro-tsx": () => import("./../../../src/pages/apple-vision-pro.tsx" /* webpackChunkName: "component---src-pages-apple-vision-pro-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-holoverzum-mr-tsx": () => import("./../../../src/pages/holoverzum-mr.tsx" /* webpackChunkName: "component---src-pages-holoverzum-mr-tsx" */),
  "component---src-pages-holoverzum-tsx": () => import("./../../../src/pages/holoverzum.tsx" /* webpackChunkName: "component---src-pages-holoverzum-tsx" */),
  "component---src-pages-holoverzum-vr-tsx": () => import("./../../../src/pages/holoverzum-vr.tsx" /* webpackChunkName: "component---src-pages-holoverzum-vr-tsx" */),
  "component---src-pages-holoverzum-xr-tsx": () => import("./../../../src/pages/holoverzum-xr.tsx" /* webpackChunkName: "component---src-pages-holoverzum-xr-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kapcsolat-tsx": () => import("./../../../src/pages/kapcsolat.tsx" /* webpackChunkName: "component---src-pages-kapcsolat-tsx" */),
  "component---src-pages-koszonjuk-tsx": () => import("./../../../src/pages/koszonjuk.tsx" /* webpackChunkName: "component---src-pages-koszonjuk-tsx" */),
  "component---src-pages-okosvaros-tsx": () => import("./../../../src/pages/okosvaros.tsx" /* webpackChunkName: "component---src-pages-okosvaros-tsx" */),
  "component---src-pages-referenciak-tsx": () => import("./../../../src/pages/referenciak.tsx" /* webpackChunkName: "component---src-pages-referenciak-tsx" */),
  "component---src-pages-rolunk-tsx": () => import("./../../../src/pages/rolunk.tsx" /* webpackChunkName: "component---src-pages-rolunk-tsx" */),
  "component---src-pages-szechenyi-2020-tsx": () => import("./../../../src/pages/szechenyi2020.tsx" /* webpackChunkName: "component---src-pages-szechenyi-2020-tsx" */),
  "component---src-pages-szolgaltatasok-tsx": () => import("./../../../src/pages/szolgaltatasok.tsx" /* webpackChunkName: "component---src-pages-szolgaltatasok-tsx" */),
  "component---src-templates-blog-post-detail-page-tsx": () => import("./../../../src/templates/BlogPostDetailPage.tsx" /* webpackChunkName: "component---src-templates-blog-post-detail-page-tsx" */),
  "component---src-templates-project-detail-page-tsx": () => import("./../../../src/templates/ProjectDetailPage.tsx" /* webpackChunkName: "component---src-templates-project-detail-page-tsx" */),
  "component---src-templates-service-page-tsx": () => import("./../../../src/templates/ServicePage.tsx" /* webpackChunkName: "component---src-templates-service-page-tsx" */)
}

